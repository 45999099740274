import { lazy } from "react";

export const CampaignDetailsPage = lazy(() => import("./CampaignDetails"));
export const CampaignDetailsSettingsPage = lazy(
  () => import("./CampaignDetailsSettings"),
);
export const CampaignsPage = lazy(() => import("./Campaigns"));
export const EventPage = lazy(() => import("./Event"));
export const ForgotPasswordPage = lazy(() => import("./ForgotPassword"));
export const HomePage = lazy(() => import("./Home"));
export const LoginPage = lazy(() => import("./Login"));
export const ManageGuestsPage = lazy(() => import("./ManageGuests"));
export const NoMatch = lazy(() => import("./NoMatch"));
export const ProfilePage = lazy(() => import("./Profile"));
export const ProjectPage = lazy(() => import("./Project"));
export const ProjectJoinPage = lazy(() => import("./ProjectJoin"));
export const ProjectSettingsPage = lazy(() => import("./ProjectSettings"));
export const RsvpPage = lazy(() => import("./Rsvp"));
export const SignUpPage = lazy(() => import("./SignUp"));
