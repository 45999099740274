import {
  CampaignDetailsPage,
  CampaignDetailsSettingsPage,
  CampaignsPage,
  EventPage,
  ForgotPasswordPage,
  HomePage,
  LoginPage,
  ManageGuestsPage,
  NoMatch,
  ProfilePage,
  ProjectJoinPage,
  ProjectPage,
  ProjectSettingsPage,
  RsvpPage,
  SignUpPage,
} from "pages";
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const AppRouter = () => {
  return (
    <Router>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/sign-up">
            <SignUpPage />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordPage />
          </Route>

          <Route path="/rsvp/:projectSlug">
            <RsvpPage />
          </Route>

          <PrivateRoute path="/home">
            <HomePage />
          </PrivateRoute>
          <PrivateRoute path="/profile">
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path="/project/:projectId">
            <ProjectPage />
          </PrivateRoute>
          <PrivateRoute exact path="/project/:projectId/join">
            <ProjectJoinPage />
          </PrivateRoute>
          <PrivateRoute exact path="/project/:projectId/settings">
            <ProjectSettingsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/project/:projectId/guests">
            <ManageGuestsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/project/:projectId/campaigns">
            <CampaignsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/project/:projectId/campaigns/:campaignId">
            <CampaignDetailsPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/project/:projectId/campaigns/:campaignId/settings"
          >
            <CampaignDetailsSettingsPage />
          </PrivateRoute>
          <PrivateRoute path="/project/:projectId/event/:eventId">
            <EventPage />
          </PrivateRoute>

          <Redirect exact from="/" to="/home" />

          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
